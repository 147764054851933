<template>
  <div class="orders">
    <div class="card_hor card round actions">
      <header class="card-header">{{ $t('Orders.货运流程') }}</header>
      <div class="grid">
        <div
          class="cell"
          @click="
            $router.push({
              name: 'PackList',
              query: { status: 'outstock', type: 'package' },
            })
          "
        >
          <div class="badge" v-show="stats.pkg_OutStock">
            {{ stats.pkg_OutStock }}
          </div>
          <div class="icon warehousing"></div>
          <div class="name">{{ $t('Orders.预报包裹') }}</div>
        </div>

        <div
          class="cell"
          @click="
            $router.push({
              name: 'PackList',
              query: { status: 0, type: 'package' },
            })
          "
        >
          <div class="badge" v-show="stats.pkg_InStock">
            {{ stats.pkg_InStock }}
          </div>
          <div class="icon warehoused"></div>
          <div class="name">{{ $t('Orders.9mbfgh') }}</div>
        </div>

        <div
          class="cell"
          @click="
            $router.push({
              name: 'OrderListProcessing',
              query: { status: -100, type: 'order' },
            })
          "
        >
          <div class="badge" v-show="stats.od_Zhuanyun">
            {{ stats.od_Zhuanyun }}
          </div>
          <div class="icon load"></div>
          <div class="name">{{ $t('Orders.处理中') }}</div>
        </div>

        <div
          class="cell"
          @click="
            $router.push({
              name: 'OrderListShipping',
              query: { status: -101, type: 'order' },
            })
          "
        >
          <div class="badge" v-show="stats.od_Shipping">
            {{ stats.od_Shipping }}
          </div>
          <div class="icon on-the-way"></div>
          <div class="name">{{ $t('Orders.z9yr5v') }}</div>
        </div>
        <div
          class="cell"
          @click="
            $router.push({
              name: 'OrderListChecked',
              query: { status: -20, type: 'order' },
            })
          "
        >
          <div class="badge hidden" v-show="stats.od_Qianshou">
            {{ stats.od_Qianshou }}
          </div>
          <div class="icon order-checked"></div>
          <div class="name">{{ $t('Orders.f7pf4j') }}</div>
        </div>
      </div>
    </div>
    <!-- 我的包裹 -->
    <div class="card round">
      <header class="card-header">{{ $t('Orders.集运工具') }}</header>

      <!-- .-1待入库的，0已入库1已发货2待认领，3问题包 -->
      <div class="grid">
        <router-link tag="div" class="cell" :to="{ name: 'EnterPackage' }">
          <div class="icon enter"></div>
          <div class="name">{{ $t('Orders.pg4hvd') }}</div>
        </router-link>
        <div
          class="cell"
          @click="
            $router.push({
              name: 'PackList',
              query: { status: -10, type: 'package' },
            })
          "
        >
           
          <div class="icon">
            <font-awesome-icon
              icon="box"
              size="2x"
              style="color: rgb(156 155 182)"
            />
          </div>
          <div class="name">{{ $t('Orders.全部包裹') }}</div>
        </div>

        <div
          class="cell"
          @click="
            $router.push({
              name: 'PackList',
              query: { status: 2, type: 'package' },
            })
          "
        >
          <div class="badge" v-show="stats.pkg_Lost">{{ stats.pkg_Lost }}</div>
          <div class="icon claim"></div>
          <div class="name">{{ $t('Orders.cbi5ag') }}</div>
        </div>
        <div
          class="cell"
          @click="
            $router.push({
              name: 'PackList',
              query: { status: 3, type: 'package' },
            })
          "
        >
          <div class="badge" v-show="stats.pkg_Problem">
            {{ stats.pkg_Problem }}
          </div>
          <div class="icon problem-package"></div>
          <div class="name">{{ $t('Orders.yrfvav') }}</div>
        </div>

        <div
          class="cell"
          @click="
            $router.push({
              name: 'RecStorageAddr',
            })
          "
        >
          <div class="icon">
            <font-awesome-icon
              icon="map-marker"
              size="2x"
              style="color: rgb(156 155 182)"
            />
          </div>
          <div class="name">{{ $t('Orders.收货仓库') }}</div>
        </div>
        <div
          class="cell"
          @click="
            $router.push({
              name: 'AddressList',
            })
          "
        >
          <div class="icon">
            <font-awesome-icon
              icon="address-book"
              size="2x"
              style="color: rgb(156 155 182)"
            />
          </div>
          <div class="name">{{ $t('Orders.客户地址') }}</div>
        </div>
      </div>
    </div>
    <!-- 运单中心 -->
    <div class="card round hidden">
      <header class="card-header">{{ $t('Orders.bmz3lu') }}</header>
      <div class="grid">
        <div
          class="cell"
          @click="
            $router.push({
              name: 'OrderList',
              query: { status: -10, type: 'order' },
            })
          "
        >
          <div class="badge" v-show="stats.od_Total">
            {{ stats.od_Total }}
          </div>
          <div class="icon">
            <font-awesome-icon
              icon="database"
              size="2x"
              style="color: rgb(156 155 182)"
            />
          </div>
          <div class="name">{{ $t('Orders.全部订单') }}</div>
        </div>

        <div
          class="cell"
          @click="
            $router.push({
              name: 'OrderList',
              query: { status: -12, type: 'order' },
            })
          "
        >
          <div class="badge" v-show="stats.od_Cancel">
            {{ stats.od_Cancel }}
          </div>
          <div class="icon canceled"></div>
          <div class="name">{{ $t('Orders.eif2md') }}</div>
        </div>
        <div
          class="cell"
          @click="
            $router.push({
              name: 'OrderList',
              query: { status: -3, type: 'order' },
            })
          "
        >
          <div class="badge" v-show="stats.od_ErropExp">
            {{ stats.od_ErropExp }}
          </div>
          <div class="icon problem-order"></div>
          <div class="name">{{ $t('Orders.30go9b') }}</div>
        </div>
        <div
          class="cell"
          @click="
            $router.push({
              name: 'OrderList',
              query: { status: -11, type: 'order' },
            })
          "
        >
          <div class="badge" v-show="stats.od_Tuihuo">
            {{ stats.od_Tuihuo }}
          </div>
          <!-- <div class="icon problem-order"></div> -->
          <font-awesome-icon icon="box-tissue" size="2x" class="icon-primary" />
          <div class="name">{{ $t('Orders.退货单') }}</div>
        </div>
      </div>
    </div>
    <!-- 打包单 -->
    <div class="card round hidden">
      <header class="card-header">{{ $t('Orders.wwampb') }}</header>
      <div class="grid">
        <div
          class="cell"
          @click="$router.push({ name: 'OrderQuery', query: { status: 1 } })"
        >
          <div class="icon package"></div>
          <div class="name">{{ $t('Orders.vthnqw') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStatsData } from '@/api/deliver.js';
export default {
  data() {
    return {
      stats: {},
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$toast.loading();
      getStatsData({})
        .then(res => {
          this.stats = res;
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.orders {
  padding: rem(20px);
}
.card_hor {
  background-color: #ffffff;
  padding-bottom: 0;
  margin-bottom: rem(25px);
  box-shadow: 3px 3px 3px #e2dfdf;
  &.round {
    border-radius: 0.15rem;
  }
  &.list {
    padding: rem(24px);
  }
  & + .card {
    margin-top: rem(20px);
  }
  &.actions {
    .van-icon,
    .icon {
      font-size: 30px;
      margin-bottom: rem(26px);
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 20%);
  }
}
.card {
  margin-bottom: rem(30px);
  background-color: #fff;
}

.card-header {
  padding: rem(20px);
  border-bottom: 1px solid #f6f6f6;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 25%);
}

.cell {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: rem(158px);
  border-right: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  position: relative;
  .name {
    font-size: 12px;
  }
}

.icon {
  width: rem(60px);
  height: rem(60px);
  background-size: contain;
  background-repeat: no-repeat;
  &.enter {
    background-image: url(~@/assets/images/enter.png);
  }
  &.warehousing {
    background-image: url(~@/assets/images/warehousing.png);
  }
  &.warehoused {
    background-image: url(~@/assets/images/warehoused.png);
  }
  &.packed {
    background-image: url(~@/assets/images/packed.png);
  }
  &.delivered {
    background-image: url(~@/assets/images/delivered.png);
  }
  &.claim {
    background-image: url(~@/assets/images/claim.png);
  }
  &.problem-package {
    background-image: url(~@/assets/images/problem-package.png);
  }
  &.on-the-way {
    background-image: url(~@/assets/images/ship.png);
  }
  &.load {
    background-image: url(~@/assets/images/boxload.png);
  }
  &.order-checked {
    background-image: url(~@/assets/images/orderchecked.png);
  }
  &.canceled {
    background-image: url(~@/assets/images/canceled.png);
  }
  &.problem-order {
    background-image: url(~@/assets/images/problem-order.png);
  }
  &.processing {
    background-image: url(~@/assets/images/processing.png);
  }
  &.package {
    background-image: url(~@/assets/images/package.png);
  }
}
.badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ee0a24;
  color: #fff;
  font-size: 10px;
  border-radius: 50%;
  padding: 0.03rem 0.07rem;
}
</style>
